import {navigate} from 'gatsby';
import {useQuery} from '@apollo/client';
import {
    Tabs,
    Button,
    LoadingIndicator,
} from '../../../../components/core';
import {Layout, StatusBar} from '../../../../components/layout';
import {NavArrowLeftIcon} from '../../../../components/core/icons';
import Timeline from '../../../../components/core/timeline/timeline';
import {
    GetChangelogDocument,
    GetChangelogQuery,
    GetPlaylistDocument,
} from '../../../../graphql-types';
import {getPlaylistTabsData} from '../../../../configs/pages/playlists/tabs';
import * as layoutStyles from '../../../../components/layout/layout.module.css';

type GetPlaylistChangelogPageType = {
    playlist: string,
};

const PlaylistChangelogPage = ({
    playlist: playlistId,
}: GetPlaylistChangelogPageType) => {
    const playlistTabsData = getPlaylistTabsData(true);
    const {loading, data} = useQuery<GetChangelogQuery>(GetChangelogDocument, {
        variables: {
            filter: {
                playlistIds: [playlistId],
            },
        },
    });

    const {data: playlistData} = useQuery(GetPlaylistDocument, {variables: {playlistId}});
    const playlistTitle = playlistData ? playlistData.playlist?.name : playlistId;
    const pageTitle = `Playlist - changelog | ${playlistTitle} `;

    return (
        <>
            <Layout
                title={pageTitle}
                scope="videos.write"
            >
                {loading && (
                    <LoadingIndicator/>
                )}
                <div className={layoutStyles.pageHeader}>
                    <StatusBar
                        buttons={(
                            <>
                                <Button
                                    icon={NavArrowLeftIcon}
                                    text="Back to playlists"
                                    onClick={() => {
                                        navigate('../../');
                                    }}
                                    variant="outline"
                                />
                            </>
                        )}
                        title={(
                            <>
                                <span>Playlist /&nbsp;</span>
                                <strong>{playlistTitle}</strong>
                            </>
                        )}
                    />
                </div>
                <div>
                    <Tabs
                        data={playlistTabsData}
                    />
                </div>
                <div className={layoutStyles.pageContent}>
                    {data && (
                        <Timeline
                            data={data}
                            category='Playlist'
                            hasIds={false}
                            isVideoTimeline={true}
                        />
                    )}
                </div>
            </Layout>
        </>
    );
};

export default PlaylistChangelogPage;
